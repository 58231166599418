<template>
  <div>
    <CCards
        icon="fa fa-user"
        caption="Tipo de Permiso"
        btn_name="Agregar Permiso"
        btn_name1="ocultar"
        @show_modal="mtd_open_modal"
        :button="cp_button_busqueda"
    >
        <CRow>
            <CCol sm="6">
            <cSelectForm
                label="Seleccione Tipo Permiso"
                :options="data_roles"
                placeholder="Escoja un Tipo Permiso"
                :value.sync="form_add_role.role_id" 
                @change="form_selected"           
            />
            </CCol>
            <CCol sm="6">
                <button type="button" :disabled="vue.estado == 1 ? true : false" style="margin-top:5.5%;width:25%" :class="btnClasses" @click="loadTable"><i class="fas fa-search"></i>&nbsp; Buscar</button>
            </CCol>
            <CInput
                type="hidden"
                label=""
                placeholder=""
                v-model="roles_add.ids"
            />
        </CRow>
    </CCards>

    <CRow v-if="vue.exp">
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          striped
          border
          small
          fixed
          :caption="vue.texto"
          icon="fas fa-user-times"
          btn_name="ocultar"
          :showhide_buttons="vue.showhide"
          @action_ver="mtd_show"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
        />
      </CCol>
    </CRow>


    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow v-if="vue.exp">
        <CCol sm="12">
            <CTableAssignPermission
            :items="data_permisos_faltan"
            :fieldsp="fieldsp"
            hover
            striped
            border
            small
            fixed
            caption="Lista de Permisos"
            icon="fas fa-user-times"
            btn_name="ocultar"
            @action_add="action_add"
            :size="'md'"
            />
        </CCol>
        </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Permiso"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>


<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CTableAssignPermission from "../../components/shared/datatable/TableAssigPermission.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import cSelectForm from '../../components/shared/inputs/cSelect.vue';
import CCards from "../../components/shared/containers/Cards.vue";
import { mapActions } from "vuex";
import { bus } from '../../main'

export default {
  components: { CTableWrapper, CTableAssignPermission, CModalForm, cModalDelete, cSelectForm, CCards },
  data() {
    return {
      prefix: "assigned_permissions",
      vue:{
          exp: false,
          texto: '',
          estado: 1,
          showhide: Array // dato para los estados de los botones
      },
      fields: [
        "Id",
        "Descripcion" // resource
      ],
      fieldsp: [
        "Id",
        "Descripcion" // resource
      ],
      data: [],
      data_roles:[],
      data_permisos_faltan:[],
      array: [],
      form_add_role:{
        role_id:"",
        permission_id:"",
        state:1
      },
      roles_add:{
        ids: ""
      },
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      permissions: {
        id: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_button: function () {
      if (this.array != "") return false;
      return true;
    },
    cp_button_busqueda: function () {
      if (this.form_add_role.role_id != "") return false;
      return true;
    },
    btnClasses () {
        return [`btn btn-${this.color || 'primary'}`]
    }
  },
  created() {
    this.mtd_getdata_typespermissions();
  },
  methods: {
    ...mapActions(["get", "post"]),
    form_selected: function(){
      this.vue.estado = 0;
    },
    mtd_getdata_typespermissions: function () {
      this.get({
        url: this.$store.getters.get__url + "/rol",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_roles = response.data_roles;
          
          // Enviar Estados para los Botones
          this.vue.showhide = [{"btn_ver": 0, "btn_pass": 0, "btn_edit": 0, "btn_del": 1 }];
        })
        .catch((errors) => {});
    },
    mtd_getdata_captionrol: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/rol/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.vue.texto = "Lista de Permisos: " + response.name;
        })
        .catch((errors) => {});
    },
    mtd_getdata_permissionxrol: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix + "/" + id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
            this.data = response.data;
        })
        .catch((errors) => {});
    },
    mtd_getdata_obtenerpermissionxrol: function(id){
        this.get({
            url: this.$store.getters.get__url + "/" + this.prefix +"/" + id,
            token: this.$store.getters.get__token,
        }).then((response) => {
            let contar = response.data.length;
            let permisos = "";
            for (let index = 0; index < contar; index++) {
                permisos = permisos + response.data[index].permission_id + ",";
            }
            this.roles_add.ids = permisos.substr(0, permisos.length - 1);

        }).catch((errors) => {})
    },
    mtd_data_permissionfaltan: function(){
        this.post({
            url: this.$store.getters.get__url + "/" + this.prefix +"/get",
            token: this.$store.getters.get__token,
            params:{
                permisos: this.roles_add.ids
            }
        }).then((response) => {
            this.data_permisos_faltan = response.data;
        }).catch((errors) => {});
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Asignar Permiso")
        : (this.modal.title = "Editar Permiso");

        this.mtd_data_permissionfaltan();
    },
    loadTable: function(){
        let id = this.form_add_role.role_id;
        if (id!=0) {
            this.vue.exp = true;
        }else{
            this.vue.exp = false;
        }
  
        this.mtd_getdata_captionrol(id);
        this.mtd_getdata_obtenerpermissionxrol(id);
        this.mtd_getdata_permissionxrol(id);
    },
    action_add: function(item){
      let index = this.array.indexOf(item);

      if(index != -1){
        this.array.splice(index, 1);
      }else{
        this.array.push(item);
      }
    },
    mtd_action: function () {
      let role_id = this.form_add_role.role_id;
      let permisos = this.array.toString();

      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix +"/store",
        params: {
          role_id: role_id,
          cadena: permisos
        },
        token: this.$store.getters.get__token
      }).then((response) => {
        let id;
        response.forEach((element)=>{
          id = element["role_id"];
        });
        this.mtd_getdata_captionrol(id);
        this.mtd_getdata_permissionxrol(id);
        this.mtd_getdata_obtenerpermissionxrol(id);

        let color = "success";
        let message = "REGISTRADO CORRECTAMENTE";

        this.modal = {
          action: "",
          title: "",
          modal_form: false,
          name: "",
          description: "",
        };
        bus.$emit('alert', { 
          color:color,
          message:message
        });
      }).catch((errors) => {});
    },
    mtd_show: function (id) {
      console.log("mtd_show");
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          permission_id: this.modal_delete.item.permission_id,
          role_id: this.modal_delete.item.role_id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete= {
            boo: false,
            item: [],
          };
          bus.$emit('alert', { 
            color:'success',
            message:'ELIMINADO CORRECTAMENTE'
          });

          let id = this.form_add_role.role_id;
          this.vue.exp = true;

          this.mtd_getdata_captionrol(id);
          this.mtd_getdata_permissionxrol(id);
          this.mtd_getdata_obtenerpermissionxrol(id);
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>